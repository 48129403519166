body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply text-body;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-body;
  }
}

.double-border::before {
  content: '';
  position: absolute;
  top: 6px;
  left: 6px;
  right: -6px;
  bottom: -6px;
  border: 3px solid #000;
  z-index: -1;
}

@keyframes pulse-glow {
  0%, 100% {
    box-shadow: 0 0 5px 2px rgba(255, 165, 0, 0.7);
  }
  50% {
    box-shadow: 0 0 20px 10px rgba(255, 165, 0, 0.3);
  }
}

.animate-pulse-glow {
  animation: pulse-glow 2s infinite;
}

@keyframes pulse-glow-scale {
  0%, 100% {
    box-shadow: 0 0 3px 1px rgba(255, 165, 0, 0.5);
    background-color: white;
    transform: scale(1);
  }
  50% {
    box-shadow: 0 0 12px 6px rgba(255, 165, 0, 0.2);
    background-color: #FFC966;
    color: #333;
    transform: scale(1.03);
  }
}

.animate-pulse-glow-scale {
  animation: pulse-glow-scale 3s infinite;
}

@keyframes vibrate {
  0% { transform: translate(0, 0) rotate(-2deg); }
  25% { transform: translate(0.5px, 0.5px) rotate(-1.5deg); }
  50% { transform: translate(0, 0) rotate(-2deg); }
  75% { transform: translate(-0.5px, 0.5px) rotate(-2.5deg); }
  100% { transform: translate(0, 0) rotate(-2deg); }
}

.vibrate {
  animation: vibrate 3s linear infinite;
}
